.canvas_sidebar {
  width: 18.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.canvas_sidebar_header {
  color: $purple-header;
  background-color: $background-header;
}

.canvas_sidebar_section_actions {
  background-color: $white;
  padding: 1rem;
}

.canvas_container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
}

.canvas_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #CDCDCD;
}

.canvas_layer {
  order: 2;
  align-self: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.canvas_sidebar {
  order: 3;
}

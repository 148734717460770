.products-grid-container {
  .products-cards__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    grid-gap: 1rem;

    .product-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .product-card_header_text {
      flex: 0 0 87%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.products-table-title {
  font-size: 0.65rem;
  letter-spacing: 1px;
}
.products-table-values {
  font-size: 0.8125rem;
}

//
// Stroke Badge
//


// General styles

.badge-stroke {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    text-transform: none;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    

    strong {
        color: $gray-800;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        width: 48px;
        height: 10px;
        border-radius: 20px;
        margin-right: .375rem;
    }

}
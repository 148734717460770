.template-previews-grid-container {
  .template-previews-cards__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    grid-gap: 1rem;

    .template-previews-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .template-previews-card_header_text {
      flex: 0 0 87%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.overflow-container {
  width: 630px;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 4px; /* width of the entire scrollbar */

    &-track {
      background: transparent; /* color of the tracking area */
    }

    &-thumb {
      background-color: $gray-200; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 5px solid $white;  /* creates padding around scroll thumb */
    }
  }
}

.carousel {
  width: 262px !important;
  margin: auto;
}
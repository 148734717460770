.project_card {
  flex-grow: 1;
}


.project_card_button_disabled {
  background-color: $lavender-mist;
  border-color: $lavender-mist;
  color: $dusty-violet;
  &:hover {
  background-color: $lavender-mist;
  border-color: $lavender-mist;
  color: $dusty-violet;
  transform: translateY(-1px);
  }
}

.project_card_disabled.pt-3.pb-1.ps-4.card-header h3 {
  color: $dusty-violet;
}
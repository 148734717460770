.feed_export_platform-grid-container {
  .feed_export_platform-cards__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: 1rem;

    .platform-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .platform-card_header_text {
      flex: 0 0 87%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

//
// Form extend
//


// Color variations

.form-primary {
  .form-control {
    color: color-contrast(map-get($theme-colors, "primary"));
    background-color: transparentize(darken(map-get($theme-colors, "primary"), 10%), .5);
    border-color: darken(map-get($theme-colors, "primary"), 10%);

    &:focus {
      background-color: transparentize(darken(map-get($theme-colors, "primary"), 12%), .3);
      border: $input-border-width solid darken(map-get($theme-colors, "primary"), 12%);
    }

    // Placeholder
    &::placeholder {
      color: transparentize(color-contrast(map-get($theme-colors, "primary")), .2);
    }
  }

  .input-group-text {
    color: color-contrast(map-get($theme-colors, "primary"));
    background-color: transparentize(darken(map-get($theme-colors, "primary"), 10%), .5);
    border-color: darken(map-get($theme-colors, "primary"), 10%);
  }

  .focused {
    .input-group-text {
      color: color-contrast(map-get($theme-colors, "primary"));
      background-color: transparentize(darken(map-get($theme-colors, "primary"), 12%), .3);
      border-color: darken(map-get($theme-colors, "primary"), 12%);
    }
  }
}

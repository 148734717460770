.section-nucleo-icons {

  --icon-size: 5rem;
  --icon-sm-size: 3.75rem;
  --gutter: 7rem;

  .icons-container {
    position: relative;
    max-width: 100%;
    height: 360px;
    margin: 0 auto;
    z-index: 1;

    i {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $white;
      z-index: 1;
      transform: translate(-50%, -50%);
      @include box-shadow($box-shadow);
      transition: all .2s cubic-bezier(.25, .65, .9, .75);

      &.icon {
        width: var(--icon-size);
        height: var(--icon-size);
        font-size: 1.7em;
      }

      &.icon-sm {
        width: var(--icon-sm-size);
        height: var(--icon-sm-size);
        font-size: 1.5em;
      }

      &:nth-child(1) {
        font-size: 42px;
        color: map-get($theme-colors, "warning");
        z-index: 2;
      }
    }

    i {
      opacity: 1;

      &:nth-child(1) {
        left: 50%;
        top: 50%;
        font-size: 42px;
        color: map-get($theme-colors, "warning");
      }

      &:nth-child(2) {
        left: calc(50% + (var(--gutter) * 1.7));
        top: 50%;
      }

      &:nth-child(3) {
        left: calc(50% + var(--gutter));
        top: calc(50% + var(--gutter));
      }

      &:nth-child(4) {
        left: calc(50% + var(--gutter));
        top: calc(50% - var(--gutter));
      }

      &:nth-child(5) {
        left: calc(50% + (var(--gutter) * 4));
        top: 50%;
      }

      &:nth-child(6) {
        left: calc(50% + (var(--gutter) * 2.7));
        top: calc(50% + (var(--gutter) * 1.5));
      }

      &:nth-child(7) {
        left: calc(50% + (var(--gutter) * 2.7));
        top: calc(50% - (var(--gutter) * 1.5));
      }

      &:nth-child(8) {
        left: calc(50% - (var(--gutter) * 1.7));
        top: 50%;
      }

      &:nth-child(9) {
        left: calc(50% - var(--gutter));
        top: calc(50% + var(--gutter));
      }

      &:nth-child(10) {
        left: calc(50% - var(--gutter));
        top: calc(50% - var(--gutter));
      }

      &:nth-child(11) {
        left: calc(50% - (var(--gutter) * 4));
        top: 50%;
      }

      &:nth-child(12) {
        left: calc(50% - (var(--gutter) * 2.7));
        top: calc(50% + (var(--gutter) * 1.5));
      }

      &:nth-child(13) {
        left: calc(50% - (var(--gutter) * 2.7));
        top: calc(50% - (var(--gutter) * 1.5));
      }

        }
    }
}

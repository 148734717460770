.flex-basis-5 {
  flex-basis: 5% !important;
}

.flex-basis-10 {
  flex-basis: 10% !important;
}

.flex-basis-15 {
  flex-basis: 15% !important;
}

.flex-basis-20 {
  flex-basis: 20% !important;
}

.flex-basis-25 {
  flex-basis: 25% !important;
}

.flex-basis-30 {
  flex-basis: 30% !important;
}

.flex-basis-35 {
  flex-basis: 35% !important;
}

.flex-basis-40 {
  flex-basis: 40% !important;
}

.flex-basis-45 {
  flex-basis: 45% !important;
}

.flex-basis-50 {
  flex-basis: 50% !important;
}

.flex-basis-55 {
  flex-basis: 55% !important;
}

.flex-basis-60 {
  flex-basis: 60% !important;
}

.flex-basis-65 {
  flex-basis: 65% !important;
}

.flex-basis-70 {
  flex-basis: 70% !important;
}

.flex-basis-75 {
  flex-basis: 75% !important;
}

.flex-basis-80 {
  flex-basis: 80% !important;
}

.flex-basis-85 {
  flex-basis: 85% !important;
}

.flex-basis-90 {
  flex-basis: 90% !important;
}

.flex-basis-95 {
  flex-basis: 95% !important;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

.feed_export_rule_action_combine_input {
  border: 0px;
  float: left;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.feed_export_rule_action_combine_input:focus {
  color: #8898aa;
  background-color: #fff;
  border-color: #5e72e4;
  outline: 0;
  box-shadow: none;
}

.feed_export_rule_action_combine_select {
  float: left
}

.feed_export_rule_action_combine_box {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 21px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-bottom: 10px;
}

.feed_export_rule_action_combine_select_remove {
  float: left;
  width: 1.3rem;
  height: 1.3rem;
}

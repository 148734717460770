.feed_export_item {
  width: 15% !important;
  height: 120px;
}

.feed_export_item_actions {
  display: flex;
  width: 14rem;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;

  & > .feed_export_item_actions_toggle {
    order: 2;
    /*align-self: flex-start;*/
  }

  & > .feed_export_item_actions_buttons {
    order: 1;
    align-self: flex-end;
    display: flex;
  }

}

//tooltip
.tooltip-hover {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    background-color: $secondary;
    color: $purple-header;
    box-shadow: 0px 1px 3px rgba(50, 50, 93, 0.15), 0px 1px 0px rgba(0, 0, 0, 0.02);
    text-align: center;
    border-radius: 10px;
    padding: 6px 8px;
    position: absolute;
    z-index: 1;
    bottom: 114%;
    left: 127%;
    margin-left: -60px;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}


.selectable_list {
  list-style: none;
  cursor: pointer;

  & > li > p.selected {
    background-color: $primary;
    color: white;
  }

  & > li > p:hover {
    background-color: $primary;
    color: white;
  }
}

.view_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .back-button {
    order: 1;
    align-self: flex-start;
  }

  .title {
    margin-left: 2rem;
    order: 2;
    align-self: flex-start;
    margin-right: auto;
  }

  .date-selector {
    margin-left: 2rem;
    order: 3;
    align-self: flex-end;
  }

  .button_actions {
    order: 3;
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}



.product-image-detail {
  max-width: 300px;
  max-height: 300px;
  box-shadow: 0px 4px 10px #E9E8EE;
}

.product-image-table {
  width: 40px;
  height: 40px;
  object-fit: contain;

}

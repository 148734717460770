.data_history_date_selector {
  z-index: 200;
  position: relative;
}

.data_history_date_selector_box {
  position: absolute;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.24) -3px 3px 21px;
}


.feed_export_rules_item {
  cursor: pointer;
}

.feed_export_rules_item.disabled {
  color: rgba(170, 199, 229, 0.48) !important;
}

.feed_export_rules_item.selected {
  background-color:$pale-blue;
}
.feed_export_rules_box.d-flex.flex-column {
  height: auto;
  max-height: 550px;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 3px;
    height: 0px;

      &-track {
        background: #fff;
      }

      &-thumb {
        background: #d0d0d0;
    }
  }
}


.feed_export_drag_button {
  cursor: grab;
  color: $light-gray;

  &.selected {
    color: $white;
  }
}

.fe-height-statement {
  height: 52px;
  width: 53px;
}

.css-13cymwt-control {
  height: 50px;
  background: $white;
  border: 1px solid $gray-300 !important;
  box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
  color: $dusty-violet;
}
span.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1dimb5e-singleValue {
  color: $purple-header !important;
  text-transform: uppercase;
}
.css-1xc3v61-indicatorContainer {
  color: $purple-header !important;
}



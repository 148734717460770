/*Header*/
.video-container {
  max-width: 900px;
  height: 457px;
  margin: 0 auto;
  position: relative;
}

.bg-button {
  background: $white !important;
  box-shadow: 0 10px 36px 0 rgba(86, 3, 173, .38), 0 0 0 1px rgba(86, 3, 173, .212);
  width: 80px !important;
  height: 80px !important;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
  line-height: 40px;

  .play-button {
    color: $primary;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    font-size: 2.2rem;
  }
}


.bg-home {
  background-image: url('../../../img/landing/home-bg.png');
  background-size: unset;
  background-position: bottom;
}

/*End Header*/

.main-content.landing-page {
  overflow: hidden;
}

.padding-header {
  padding: 9rem 0;
}

.landing_header {
  font-size: 2.188rem;
  font-size: clamp(2.188rem, 1.5213333333333336rem + 1.3888888888888888vw, 3.188rem);
  line-height: 120%;
  font-weight: 700;
  color: $purple-header;
}

.landing_title {
  font-size: 0.938rem;
  font-weight: 700;
  color: $purple-header;
}

h3.title {
  font-size: 1.625rem;
  font-size: clamp(1.625rem, 0.9583333333333334rem + 1.3888888888888888vw, 2.625rem);
}

.functionalities.custom-ov-hidden {
  overflow: hidden;
}

.image-fix {
  img {
    width: 90%;
  }

  &.right {
    text-align: right;
  }
}

.main-content.landing-page {
  p, ul, li {
    font-size: 1rem;
    font-size: clamp(1rem, 0.95rem + 0.25vw, 1.25rem);
    line-height: 140%;
  }
}

.main-content.landing-page {
  li {
    margin-bottom: .5rem;
  }
}

.main-content.landing-page {
  .btn {
    font-size: 1.15rem;
    padding: 1rem 2rem;
  }
}

ul.text-lists-orange ::marker {
  color: $feedManager;
}

ul.text-lists-pink ::marker {
  color: $dataHistory;
}

strong {
  font-weight: bold;
}

.padding-clientes {
  padding: 0rem 5rem;
}

.padding-card-clientes {
  padding: 1.5rem;
}

.bg-wf {
  background-image: url('../../../img/landing/bg_2_wf.png');
  background-size: cover;
  background-position: right;
}

.author-img {
  img {
    width: 60px;
  }
}

.fa-regular.fa-circle-check {
  position: relative;
  top: 10px;
}


/* LogoCarousel */
.centered-logo {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.centered-logo img {
  max-width: 100%;
  max-height: 100%;
}


.left-image {
  position: absolute;
  bottom: 0;
  top: 380px;
  left: -263px;
  z-index: 10000;
  height: auto;
  width: auto;
}

.right-image {
  position: absolute;
  bottom: 0;
  top: 380px;
  right: -263px;
  z-index: 10000;
  height: auto;
  width: auto;
}

.hubspot-form {
  max-width: 100%;
  width: 673px;
  margin: 0 auto;
  padding: 0 1rem;
  box-shadow: 0 4px 8px 0 rgba(53, 105, 128, .3), 0 6px 20px 0 rgba(165, 200, 213, .41);
}

/*Bot widget*/

#jsd-widget {
  display: none;
}

/* Responsive */
@media (min-width: 1919px) {
  .spacing-fix {
    margin: -134px 0;
  }
}

@media (max-width: 1270px) {
  .left-image {
    display: none;
  }
  .right-image {
    display: none;
  }
  .hubspot-form {
    width: 100% !important;
  }
}

@media (max-width: 822px) {
  .padding-header {
    padding: 4rem 0;
  }
  .custom-icon-size {
    font-size: 1.5rem;
  }
  svg.navbar-brand-img {
    width: 80%;
  }
  .responsive {
    display: flex;
    flex-direction: column;
  }
  .image-fix img {
    width: 100%;
  }
  .custom-order {
    order: 2;
    width: 100%;
  }
  .custom-order-first {
    order: 1;
    width: 100%;
  }
  .custom-order-first {
    .image {
      padding-bottom: 3rem;
    }
  }
  .custom-w100 {
    width: 100%;
  }
  .custom-w75 {
    width: 75%;
  }
  .cyber-bg {
    background-size: contain;
    background-position: bottom;
  }
  .custom-p1 {
    padding: 0 1rem;
  }
  .custom-p2 {
    padding-bottom: 2.5rem;
  }
  .custom-p3 {
    padding: 1.5rem !important;
  }
  .padding-clientes {
    padding: 0rem 1rem;
  }
  .padding-card-clientes {
    padding: 0.5rem;
  }
  .logo-cyber {
    width: 56%;
  }


}

@media screen and (max-width: 720px) {
  .video-container {
    height: 304px;
  }
}

.cyberclick_skill {
  width: 8.25rem;
  height: 8.25rem;
  box-shadow: 2px 3px 7px -2px rgba(0, 0, 0, 0.2)
}

.cyberclick_description_landing {
  max-width: 35%;
}

@media (max-width: 800px) {
  .cyberclick_description {
    flex-direction: column;
  }
  .cyberclick_description_landing {
    max-width: 100%;
  }
}

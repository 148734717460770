//
// Icon buttons
//

// General styles

.btn {
  position: relative;
  text-transform: $btn-text-transform;
  transition: $transition-base;
  letter-spacing: $btn-letter-spacing;
  font-size: $input-btn-font-size;
  will-change: transform;

  &:hover {
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY($btn-hover-translate-y);
  }

  &:not(:last-child) {
    margin-right: .5rem;
  }

  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: .5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: .5rem;
  }
}

//button secondary
button.p-3.btn.btn-secondary {
  background: $lavender-mist;
  border-color: $lavender-mist;

  &:hover {
    background-color: $white;
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    transform: scale(1.08);

    a {
      color: $designer
    }
  }
}

button.p-3.btn.btn-secondary.disabled {
  pointer-events: all;

  &:hover {
    background-color: $white;
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    opacity: 1;

    a {
      color: $designer;
    }
  }
}

button.p-3.btn.btn-secondary a {
  color: $dusty-violet;
}

button.fe-menu-btn {
  background-color: $lavender-mist;

  &:hover {
    background-color: $white !important;
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    color: $primary;
    transform: scale(1.08);
    a {
      color: $primary;
    }
  }
}

.vertical-line {
  border-right: 2px solid $dusty-violet;
  height: 20px;
  margin: 0 1px;
}

button.fe-menu-btn.border.border-lavender-mist.btn.btn-secondary.active {
  background-color: $white !important;
  box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: $primary;
  transform: scale(1.08);
}

// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
  }
}


// Size variations

.btn-sm {
  font-size: $input-btn-font-size-sm;
}

.btn-lg {
  font-size: $input-btn-font-size-lg;
}


// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-outline-secondary {
  color: darken(map-get($theme-colors, "secondary"), 50%);
}

.btn-inner--icon {
  i:not(.fas):not(.fab) {
    position: relative;
    top: 2px;
  }
}

.btn-link {
  font-weight: $btn-font-weight;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.btn-neutral {
  color: map-get($theme-colors, "primary");
}
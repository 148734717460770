//
// Badge
//


// General styles

.badge {
  text-transform: $badge-text-transfom;

  a {
    color: $white;
  }
}


// Size variations

.badge-md {
  padding: .65em 1em;
}

.badge-lg {
  padding: .85em 1.375em;
}


// Multiple inline badges

.badge-inline {
  margin-right: .625rem;

  + span {
    top: 2px;
    position: relative;

    > a {
      text-decoration: underline;
    }
  }
}


// Color fixes

.badge-default {
  color: $white;
}

.badge-secondary {
  background-color: map-get($theme-colors, "secondary");
  color: color-contrast(map-get($theme-colors, "secondary"));
}

.bg-success {
  color: saturate(darken(map-get($theme-colors, "success"), 10%), 10) !important;
  background-color: lighten(map-get($theme-colors, "success"), 32%) !important;
}

.bg-warning {
  color: saturate(darken(map-get($theme-colors, "warning"), 10%), 10) !important;
  background-color: lighten(map-get($theme-colors, "warning"), 32%) !important;
}

.bg-danger {
  color: saturate(darken(map-get($theme-colors, "danger"), 10%), 10) !important;
  background-color: lighten(map-get($theme-colors, "danger"), 32%) !important;
}


// Badge spacing inside a btn with some text

.btn {
  .badge {
    &:not(:first-child) {
      margin-left: .5rem;
    }

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

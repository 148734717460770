.canvas_object_info_sidebar {
  display: flex;
  order: 4;
  flex-direction: column;
  height: 50%;
}

.canvas_object_info_element {

}

.canvas_object_info_sidebar_info {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.6em;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
  }
}

.canvas_sidebar_conditions {
  box-shadow: -3px -6px 5px -5px rgba(0, 0, 0, 0.5)
}

.outline_error {
  outline: $danger 2px solid;
  border: 2px;
}

.input_property_filter {
  background-color: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  height: 32px;
  outline: none;
  padding: 0;
}

.filter_input_row {
  position: relative;
  float: right;
}

.filter_selector {
  position: absolute;
  left: 0;
  z-index: 500;
  width: 100%;
  max-width: 200px;

  &.edit {
    left: inherit;
  }

  span {
    cursor: pointer;
  }
}

.filter_selector_item {
  &:hover {
    cursor: pointer;
    background-color: #f4f5f7;
  }
}


$md-chip-height: 32px;
$md-chip-color: #e0e0e0;

.md-chip {
  display: inline-block;
  background: $md-chip-color;
  padding: 0 12px;
  border-radius: 32px;
  font-size: 13px;

  &.md-chip-hover:hover {
    background: #ccc;
  }
}

.md-chip-clickable {
  cursor: pointer;
}

.md-chip, .md-chip-icon {
  height: $md-chip-height;
  line-height: $md-chip-height;
}

.md-chip-icon {
  display: block;
  float: left;
  background: #009587;
  width: $md-chip-height;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 0 8px 0 -12px;
}

.md-chip-remove {
  display: inline-block;
  background: #aaa;
  border: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 0;
  margin: 0 -4px 0 4px;
  cursor: pointer;
  font: inherit;
  line-height: 20px;

  &:after {
    color: $md-chip-color;
    content: 'x';
  }

  &:hover {
    background: #999;
  }

  &:active {
    background: #777;
  }
}

.md-chips {
  padding: 12px 0;

  .md-chip {
    margin: 0 5px 3px 0;
  }
}

.md-chip-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

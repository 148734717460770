.analytics-tooltip {
  font-size: 15px;
  background-color: #3F3949;
  color: white;
  border-radius: 5px;

  & > .title {
    font-size: 13px;
  }

  &:after {
    bottom: -24px;
    left: 50%;
    content: " ";
    position: absolute;
    pointer-events: none;
    border: 12px solid rgba(255, 255, 255, 0);
    border-bottom-color: #3F3949;
    margin-left: -15px;
    transform: rotate(180deg);
  }
}

@font-face {
  font-family: 'NunitoLight';
  src: url('../../../fonts/Nunito-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoRegular';
  src: url('../../../fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoRegular';
  src: url('../../../fonts/Nunito-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

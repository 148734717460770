.analytics_metric_selector {
  position: absolute;
  z-index: 200;
  box-shadow: rgba(0, 0, 0, 0.24) -3px 3px 21px;
  cursor: pointer;
  max-height: 20rem;

  overflow: auto;

  & > :hover {
    background-color: $background-header;
  }
}

.analytics_pill {
  min-width: 210px;
}

//
// Chart.js
//


#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background-color: #3F3949;
  color: white;
  border-radius: 10px;
  transition: all .1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.badge + .badge {
  margin-left: 4px;
}

.badge.badge-dot i {
  margin-right: .6rem;
}

.badge {
  min-width: 90px;
}

.canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1024px;
  width: 1024px;
  background-color: $white;
  order: 2;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
}

.canvas-grid {
  background-image: linear-gradient(to right, transparent 15px, rgba(0, 0, 0, 0.1) 15px, rgba(0, 0, 0, 0.1) 15px),
  linear-gradient(to bottom, transparent 15px, rgba(0, 0, 0, 0.1) 15px, rgba(0, 0, 0, 0.1) 15px);
  background-size: 16px 16px;
}

.center-lines::before,
.center-lines::after {
  content: "";
  position: absolute;
  background-color: $primary;
}

.center-lines::before {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px; /* Ajusta el grosor de la línea horizontal */
}

.center-lines::after {
  top: 0;
  left: 50%;
  width: 2px; /* Ajusta el grosor de la línea vertical */
  height: 100%;
}

.canvas_header_modal {
  z-index: 999999;
}

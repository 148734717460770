.canvas_object_sidebar {
  display: flex;
  order: 4;
  background-color: $background-header;
  height: 50%;
}

.canvas_sidebar_layers_box {
}

.canvas_sidebar_layers_container {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.6em;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
  }
}

.object_sidebar_box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 2px;

  &.selected {
    background-color: #D2E3EE !important;
  }

  &.error {
    outline: $danger 2px solid;
    border-radius: 2px;
  }

}

.object_sidebar_item_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-color: $black;
  border-style: solid;
  border-width: 1px;
  margin: 1rem;
  order: 2;
  width: 2.5rem;
  height: 2.5rem;
  padding-top: 2px;
  padding-bottom: 2px;
}

.object_sidebar_item_visibility {
  order: 1;
  width: 2.875rem;
}

.object_sidebar_item_text {
  order: 3;
  margin: 0 auto;
}

.object_sidebar_item_handle {
  order: 4;

  &.selected {
    color: $white;
  }
}
